@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import './reboot';
@import './mixins';
@import './variables';
@import './typography';

/* Globals */
body,
html {
    font-family: $font-default-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include large {
        font-size: calcRem(14px);
    }

    @include medium {
        font-size: calcRem(13px);
    }
}

#root {
    scroll-behavior: smooth;
    min-height: 100vh;
    background-color: $background-color;
    @include x-large {
        overflow: auto;
    }
}


.tippy-box,
.tippy-content {
    padding: 0 !important;
    background: none;
}

@keyframes loading-indicator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}